/* unplugin-vue-components disabled */import __unplugin_components_4 from 'D:/Projects/118_meijia/frontend/src/components/MPanel.vue';
import __unplugin_components_3 from 'D:/Projects/118_meijia/frontend/src/components/includes/NoData.vue';
import __unplugin_components_2 from 'D:/Projects/118_meijia/frontend/src/components/MSelect.vue';
import __unplugin_components_1 from 'D:/Projects/118_meijia/frontend/src/components/BaseSpacer.vue';
import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/MPanelHeader.vue';
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, isRef as _isRef, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "m-panel__inner"
};
var _hoisted_2 = {
  class: "arp-card__title"
};
var _hoisted_3 = {
  class: "arp-card__icon"
};
var _hoisted_4 = {
  key: 0,
  class: "icon-success-check"
};
var _hoisted_5 = {
  key: 1,
  class: "icon-remoe"
};
var _hoisted_6 = {
  class: "arp-card__status-txt"
};
import { Post } from "@/js/services/baseService";
export default {
  __name: 'AnnouncementReadStatusPanel',
  props: {
    modelValue: Boolean,
    postId: Number
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var readStatus = ref(1);
    var cData = reactive({
      options: [{
        name: '所有',
        value: 1
      }, {
        name: '已讀',
        value: 2
      }, {
        name: '未讀',
        value: 3
      }],
      list: [],
      showList: computed(function () {
        return cData.list.filter(function (item) {
          return item.limit_group_code !== 'BR_DOC';
        });
      })
    });

    var refreshList = _.debounce(function () {
      if (props.postId) {
        Post.readStatus({
          post_id: props.postId,
          read_status: readStatus.value
        }).then(function (res) {
          cData.list = res.data;
        });
      }
    }, 100);

    function updateModelValue(modelValue) {
      emit('update:modelValue', modelValue);
    }

    watch(function () {
      return props.modelValue;
    }, function () {
      readStatus.value = 1;
    });
    watch([readStatus, function () {
      return props.postId;
    }], function () {
      refreshList();
    });
    refreshList();
    return function (_ctx, _cache) {
      var _component_MPanelHeader = __unplugin_components_0;

      var _component_BaseSpacer = __unplugin_components_1;

      var _component_MSelect = __unplugin_components_2;

      var _component_NoData = __unplugin_components_3;

      var _component_MPanel = __unplugin_components_4;

      return _openBlock(), _createBlock(_component_MPanel, {
        class: "announcement-read-panel",
        "model-value": __props.modelValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return updateModelValue($event);
        })
      }, {
        header: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MPanelHeader, {
            "no-left": ""
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(" 讀取狀態 ")];
            }),
            _: 1
          }), _createVNode(_component_BaseSpacer, {
            h: "5px"
          }), _createVNode(_component_MSelect, {
            modelValue: _unref(readStatus),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _isRef(readStatus) ? readStatus.value = $event : null;
            }),
            placeholder: "讀取狀態篩選",
            options: _unref(cData).options,
            fz: "16px",
            h: "35px"
          }, null, 8, ["modelValue", "options"]), _createVNode(_component_BaseSpacer, {
            h: "20px"
          })])];
        }),
        footer: _withCtx(function () {
          return [];
        }),
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cData).showList, function (item) {
            return _openBlock(), _createElementBlock("div", {
              key: item.id,
              class: _normalizeClass(["arp-card", {
                'arp-card_read': item.is_read
              }])
            }, [_createElementVNode("div", _hoisted_2, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_3, [item.is_read ? (_openBlock(), _createElementBlock("i", _hoisted_4)) : (_openBlock(), _createElementBlock("i", _hoisted_5))]), _createElementVNode("div", _hoisted_6, _toDisplayString(item.is_read ? '已讀' : '未讀'), 1)], 2);
          }), 128)), _createVNode(_component_NoData, {
            watch: "",
            "watch-data": _unref(cData).list
          }, null, 8, ["watch-data"])];
        }),
        _: 1
      }, 8, ["model-value"]);
    };
  }
};